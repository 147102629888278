import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU15Colts() {

  return ( 
    <Layout>
    <section className="container">
    <h1>Under 15's - Colts</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u14-colts.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
            <p>We play in the Garforth League and the core of the team have been together since kindergarten days. We aim to play attacking football with an emphasis on ball possession and hard work! We also pride ourselves on competing in the right way, respecting our opponents and the referee.</p>
            <p>
                All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
            </p>
            </div>

            <div className="row border thefa-table g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 ">
            <h3>League Table</h3>
                <TheFATable leagueId="335053756"></TheFATable>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 10, DoB 01/09/2007 - 31/08/2008</dd>
                <dt>Manager(s)</dt>
                <dd>Milton McCann</dd>
                <dt>Training</dt>
                <dd>Saturday 8:45am to 10:15am at Bedquilts.</dd>
                <dt>League</dt>
                <dd>Garforth Junior League - Division 3</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:milton.mccann@gmail.com">milton.mccann@gmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsors-wanted.jpg" />
                <div className="card-body">
                    <p className="card-text">Interested in sponsoring a team? Click <Link to="/sponsors" title="Sponsors">here</Link> to find out more.</p>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

